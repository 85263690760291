/* eslint-disable quotes */
import * as Sentry from '@sentry/react';
import { CMD_VERSION } from '@webtv/constants';
import { Environment } from '@webtv/typings/shared';

export class SentryClient {
  static initialize(): void {
    Sentry.init({
      dsn: 'https://a22952a50aec49c689ed6ec6c54d9227@o1355868.ingest.sentry.io/4505402119421952',
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      debug: false,
      release: 'prod@' + CMD_VERSION,
      enabled: process.env.NODE_ENV !== Environment.Development,

      beforeSend(event) {
        if (event.exception?.values) {
          const networkError = event.exception.values.some(
            (value) => value.type === 'Error' && value.value === 'Network Error'
          );

          // https://norlys-00.sentry.io/issues/4486505691/?project=4505402119421952
          const rejectionError = event.exception.values.some(
            (value) =>
              value.type === 'UnhandledRejection' &&
              value.value === 'Object captured as promise rejection with keys: message, name'
          );

          // https://norlys-00.sentry.io/issues/4486431304/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&sort=freq&statsPeriod=30d&stream_index=2
          const kidNotDefined = event.exception.values.some(
            (value) => value.type === 'ReferenceError' && value.value === 'KID is not defined'
          );

          // https://norlys-00.sentry.io/issues/5086698031/?query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&sort=freq&statsPeriod=30d&stream_index=6
          const nullReferenceError = event.exception.values.some(
            (value) =>
              value.type === 'TypeError' &&
              value.value === "Cannot read properties of undefined (reading 'title')"
          );

          // https://norlys-00.sentry.io/issues/4486971269/?project=4505402119421952&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=issue-stream&sort=freq&statsPeriod=30d&stream_index=7
          const invalidArgumentsError = event.exception.values.some(
            (value) => value.type === 'Error' && value.value === 'Invalid Arguments'
          );

          // https://norlys-00.sentry.io/issues/4505192200/events/latest/?project=4505402119421952&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D&referrer=latest-event&sort=freq&statsPeriod=30d&stream_index=10
          const errorReadError = event.exception.values.some(
            (value) =>
              value.type === 'TypeError' &&
              value.value === "Cannot read properties of undefined (reading 'error')"
          );

          if (
            networkError ||
            rejectionError ||
            kidNotDefined ||
            nullReferenceError ||
            invalidArgumentsError ||
            errorReadError
          ) {
            return null;
          }
        }
        return event;
      }
    });

    // TODO skal fjernes senere. Bruges til at tracke om sentry fejl filteres i denne version indtil videre
    Sentry.setTag('deploy_tag', 'error-filtering-update-2024-03-version-02');
  }
}
